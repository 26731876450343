import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
      },
      {
        path: ':slug', loadChildren: () =>
          import("./shop/shop.module").then((m) => m.ShopModule)
      }
    ],
  },
  {
    path: 'n/auth',
    children: [
      {
        path: '', loadChildren: () =>
          import("./auth/auth.module").then((m) => m.AuthModule),
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
