import { LazyImageComponent } from './components/lazy-image/lazy-image.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ShopSliderComponent } from './components/shop-slider/shop-slider.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductSliderComponent } from './components/product-slider/product-slider.component';
import { ZoomImageComponent } from './components/zoom-image/zoom-image.component';

@NgModule({
  declarations: [
    LazyImageComponent,
    ShopSliderComponent,
    ProductCardComponent,
    ProductSliderComponent,
    ZoomImageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
  ],
  exports: [
    LazyImageComponent,
    ShopSliderComponent,
    ProductCardComponent,
    ProductSliderComponent
  ]
})
export class SharedModule { }
