<div class="zoom-image">
  <!-- Close -->
  <div class="close" (click)="activeModal.close()">
    <i class="fal fa-times"></i>
  </div>
  <div class="swiper-container h-100">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <div class="swiper-zoom-container w-100 h-100">
          <img class="lazyload" [attr.data-srcset]="image" [attr.data-src]="image"
            onerror="this.parentNode.classList.add('card-error')" onload="this.parentNode.classList.add('card-loaded')"/>
        </div>
      </div>
    </div>
  </div>
</div>
