import { ShopService } from 'src/app/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-center-header',
  templateUrl: './center-header.component.html',
  styleUrls: ['./center-header.component.scss']
})
export class CenterHeaderComponent implements OnInit {
  currentShop: any;
  
  constructor(private shopService: ShopService) { }

  ngOnInit(): void {
    this.shopService.currentShop.subscribe(
      (shopData) => {
        this.currentShop = shopData;
        console.log(this.currentShop)
      }
    );
  }

}
