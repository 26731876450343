import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Product, ProductListConfig } from '../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private apiService: ApiService) { }
  query(config: ProductListConfig): Observable<{ products: Product[], productsCount: number }> {
    // Convert any filters over to Angular's URLSearchParams
    const params = {};

    Object.keys(config.filters)
      .forEach((key) => {
        params[key] = config.filters[key];
      });

    return this.apiService
      .get('/products/search', new HttpParams({ fromObject: params }));
  }


  get(slug): Observable<any> {
    return this.apiService.get('/products/' + slug)
      .pipe(map(data => data));
  }
}
