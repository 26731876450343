import { Component, OnInit, Input, ElementRef, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
const lazyload = (window as any).lazyload;
declare var Swiper: any;

@Component({
  selector: 'app-zoom-image',
  templateUrl: './zoom-image.component.html',
  styleUrls: ['./zoom-image.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZoomImageComponent implements OnInit {
  @Input() image: any = "";
  constructor(
    public activeModal: NgbActiveModal,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    lazyload();
    let swiper = new Swiper(
      this.elementRef.nativeElement.querySelector(
        ".zoom-image .swiper-container"
      ),
      {
        slidesPerView: 1,
        loop: false,
        mousewheel: false,
        freeMode: false,
        lazy: true,
        preloadImages: true,
        scrollbar: false,
        effect: "fade",
        speed: 1000,
        zoom: {
          maxRatio: 2
        },
        pagination: false,
        touchStartForcePreventDefault: true
      }
    );
  }
}
