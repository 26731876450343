import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ImageService, ShopListConfig, ShopService } from 'src/app/core';
declare var Swiper: any;

@Component({
  selector: 'app-shop-slider',
  templateUrl: './shop-slider.component.html',
  styleUrls: ['./shop-slider.component.scss']
})
export class ShopSliderComponent implements OnInit {
  slideHeight: number = 0;
  slideWidth: number = 0;
  spaceBetween: number = 20;
  slidesPerView: number = 5;

  shops = [];
  query: ShopListConfig = {
    filters: {
      take: 10,
      featured: 1
    }
  };

  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private shopService: ShopService, 
    private imageService: ImageService) { }

  ngOnInit(): void {
    console.log()

    this.shopService.query(this.query)
      .subscribe((res: any) => {
        this.shops = res.data.items.map(item => {
          item.cover = this.imageService.getCFImage(item.banner.fileUrl, { width: 320, height: 320, keepFormat: true, disabledLazy: true });
          item.originalCover = this.imageService.getCFImage(item.banner.fileUrl, { keepFormat: true, disabledLazy: true });
          item.logo = this.imageService.getCFImage(item.logo.fileUrl, { width: 200, keepFormat: true, disabledLazy: true });
          item.originalLogo = this.imageService.getCFImage(item.logo.fileUrl, { keepFormat: true, disabledLazy: true });
          return item;
        });
        console.log(this.shops)
        this.progressLayout();
        this.swiperConfig();
      });
  }

  ngAfterViewInit(): void {
    // this.swiperConfig();
   
  }

  progressLayout(): void {
    let ww = window.innerWidth;
    let elm = this.elementRef.nativeElement.querySelector(".shop-slider .swiper-container");
    if (ww >= 1024) {
      this.slidesPerView = 5;
      this.spaceBetween = 20;
      this.slideWidth = (elm.offsetWidth - this.spaceBetween * (this.slidesPerView - 1)) / this.slidesPerView;
      this.slideHeight = this.slideWidth;
    }
    if (ww >= 768 && ww < 1024) {
      this.slidesPerView = 4;
      this.spaceBetween = 16;
      this.slideWidth = (elm.offsetWidth - this.spaceBetween * (this.slidesPerView - 1)) / this.slidesPerView;
      this.slideHeight = this.slideWidth;
    }
    if (ww >= 640 && ww < 768) {
      this.slidesPerView = 3.5;
      this.spaceBetween = 16;
      this.slideWidth = (elm.offsetWidth - this.spaceBetween * 3) / this.slidesPerView + this.spaceBetween / (this.slidesPerView * 2);
      this.slideHeight = this.slideWidth;
    }
    if (ww >= 481 && ww < 640) {
      this.slidesPerView = 3;
      this.spaceBetween = 16;
      this.slideWidth = (elm.offsetWidth - this.spaceBetween * 2) / this.slidesPerView;
      this.slideHeight = this.slideWidth;
    }
    if (ww >= 320 && ww < 481) {
      this.slidesPerView = 2.5;
      this.spaceBetween = 0;
      this.slideWidth = (elm.offsetWidth - this.spaceBetween * 2) / this.slidesPerView + this.spaceBetween / (this.slidesPerView * 2);
      this.slideHeight = this.slideWidth - 16;
    }


    console.log(this.slideHeight, this.slideWidth)
  }

  private swiperConfig(): void {
    setTimeout(() => {
      let elm = this.elementRef.nativeElement.querySelector(".shop-slider .swiper-container");
      let swiper = new Swiper(
        elm,
        {
          slidesPerView: this.slidesPerView,
          spaceBetween: this.spaceBetween,
          mousewheel: {
            forceToAxis: true
          },
          freeMode: true,
          lazy: {
            loadOnTransitionStart: false,
            loadPrevNext: true,
          }, // Enable lazy loading
          preloadImages: false, // Disable preloading of all images
          watchSlidesVisibility: true,
          pagination: false,
          scrollbar: false,
          updateOnWindowResize: true,
          navigation: {
            nextEl: ".shop-slider .swiper-button-next",
            prevEl: ".shop-slider .swiper-button-prev"
          },
          observer: true,
          autoplay: {
            delay: 10000,
            disableOnInteraction: false
          }, 
          on: {
            observerUpdate: () => {
              let slidesUpdate = elm.querySelectorAll(".swiper-slide");
              slidesUpdate.forEach(slide => {
                slide.style.removeProperty('height');
              })
            }
          },
          breakpoints: {
            // when window width is >= 320px
            320: {
              slidesPerView: 2.5,
              spaceBetween: 0,
              hashNavigation: false,
              loop: true
            },
            // when window width is >= 481px
            481: {
              slidesPerView: 3,
              spaceBetween: 16,
              navigation: false,
              hashNavigation: false,
              loop: false
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3.5,
              spaceBetween: 16,
              loop: false
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 4,
              spaceBetween: 16,
              navigation: false,
              loop: false
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 5,
              spaceBetween: 20,
              loop: true
            }
          }
        }
      );
      
      let selfRouter = this.router;
      elm.addEventListener("click", e => {
        let target = e.target;
        console.log(target)
        if (target) {
          let url = target.parentNode.getAttribute("rel");
          if (url) {
            selfRouter.navigate([url]);
          }
        }
      });
    });
  }
  getSlideHeight() {
    // let elm = this.elementRef.nativeElement.querySelector(".shop-slider .swiper-container");
    // elm.querySelectorAll(".swiper-slide");
    this.progressLayout();
    console.log(this.slideHeight);
    return this.slideHeight;

    // shopSlide.offsetWidth
  }
  

}
