import { Component, OnInit, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { ImageService, ProductListConfig, ProductService } from 'src/app/core';
declare var Swiper: any;


@Component({
  selector: 'app-product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductSliderComponent implements OnInit {
  @Input() options: any = {};
  @Input() features: any = {};

  imageHeight: number = 0;
  imageWidth: number = 0;
  imageSpaceBetween: number = 0;
  slideHeight: number = 0;
  slideWidth: number = 0;
  spaceBetween: number = 20;
  slidesPerView: number = 4;
  breakpoints: any = {};
  offset: any = {};

  products = [];

  query: ProductListConfig = {
    filters: {
      page: 1,
      take: 12,
      sort: "random",
    }
  };

  private subscription: Subscription;

  constructor(private elementRef: ElementRef,
    private productService: ProductService, private imageService: ImageService) { }

  ngOnInit(): void {
    this.query.filters = { ...this.options };
    this.subscription = this.productService.query(this.query)
      .subscribe((res: any) => {
        this.products = res.data.items.map(item => {
          item.image = this.imageService.getCFImage(item.mainImage.fileUrl, { width: 322, height: 322, keepFormat: true, disabledLazy: true });
          item.originalImage = this.imageService.getCFImage(item.mainImage.fileUrl, { keepFormat: true, disabledLazy: true });
          return item;
        });
        this.progressLayout();
        this.swiperConfig();
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  progressLayout(): void {

    let ww = window.innerWidth;
    let staticClasses = ".product-slider";
    let staticContainer = " .swiper-container";
    let classes = staticClasses + "." + this.features.name + staticContainer;

    let elm = this.elementRef.nativeElement.querySelector(classes);
    if (this.features.name === 'noam-pick') {
      if (ww >= 1024) {
        this.slidesPerView = 3;
        this.spaceBetween = 20;
        this.slideWidth = (elm.offsetWidth - this.spaceBetween * (this.slidesPerView - 1)) / this.slidesPerView - 107;
        this.slideHeight = this.slideWidth;
      }
      if (ww >= 768 && ww < 1024) {
        this.slidesPerView = 3;
        this.spaceBetween = 16;
        this.slideWidth = (elm.offsetWidth - this.spaceBetween * (this.slidesPerView - 1)) / this.slidesPerView - 90;
        this.slideHeight = this.slideWidth;
      }
      if (ww >= 481 && ww < 768) {
        this.slidesPerView = 2;
        this.spaceBetween = 16;
        console.log(elm.offsetWidth)
        this.slideWidth = (elm.offsetWidth - this.spaceBetween * (this.slidesPerView - 1)) / this.slidesPerView - 90;
        this.slideHeight = this.slideWidth;
      }
      if (ww >= 320 && ww < 481) {
        let pdCard = 45;
        this.slidesPerView = 1.25;
        this.spaceBetween = 0;
        let wrapperCard = elm.offsetWidth / this.slidesPerView;
        this.slideWidth = wrapperCard - pdCard * 2 - 16;
        this.slideHeight = this.slideWidth;
      }
      this.breakpoints = {
        // when window width is >= 320px
        320: {
          slidesPerView: 1.25,
          spaceBetween: 0,
          hashNavigation: false,
          freeMode: true
        },
        // when window width is >= 481px
        481: {
          slidesPerView: 2,
          spaceBetween: 16,
          hashNavigation: false,
          freeMode: true
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 2,
          spaceBetween: 16,
          hashNavigation: false,
          freeMode: false
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 16,
          hashNavigation: false,
          freeMode: false
        },
        // when window width is >= 1024px
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
          freeMode: false
        }
      }
    } else {
      if (ww >= 1024) {
        this.slidesPerView = 4;
        this.spaceBetween = 20;
        this.slideWidth = (elm.offsetWidth - this.spaceBetween * (this.slidesPerView - 1)) / this.slidesPerView;
        this.slideHeight = this.slideWidth;
      }
      if (ww >= 768 && ww < 1024) {
        this.slidesPerView = 3.5;
        this.spaceBetween = 16;
        this.slideWidth = (elm.offsetWidth - this.spaceBetween * 3) / this.slidesPerView + + this.spaceBetween / (this.slidesPerView * 2);
        this.slideHeight = this.slideWidth;
      }
      if (ww >= 640 && ww < 768) {
        this.slidesPerView = 3;
        this.spaceBetween = 16;
        this.slideWidth = (elm.offsetWidth - this.spaceBetween * (this.slidesPerView - 1)) / this.slidesPerView;
        this.slideHeight = this.slideWidth;
      }
      if (ww >= 481 && ww < 640) {
        this.slidesPerView = 2.5;
        this.spaceBetween = 16;
        this.slideWidth = (elm.offsetWidth - this.spaceBetween * 2) / this.slidesPerView + this.spaceBetween / (this.slidesPerView * 2);
        this.slideHeight = this.slideWidth;
      }
      if (ww >= 320 && ww < 481) {
        this.slidesPerView = 1.4;
        this.spaceBetween = 0;
        this.slideWidth = (elm.offsetWidth - this.spaceBetween) / this.slidesPerView + this.spaceBetween / (this.slidesPerView * 2) - 16;
        this.slideHeight = this.slideWidth - 16;
      }
      this.breakpoints = {
        // when window width is >= 320px
        320: {
          slidesPerView: 1.4,
          spaceBetween: 0,
          hashNavigation: false,
          freeMode: true
        },
        // when window width is >= 481px
        481: {
          slidesPerView: 2.5,
          spaceBetween: 16,
          hashNavigation: false,
          freeMode: true
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 3,
          spaceBetween: 16,
          hashNavigation: false,
          freeMode: false
        },
        768: {
          slidesPerView: 3.5,
          spaceBetween: 16,
          hashNavigation: false,
          freeMode: false
        },
        // when window width is >= 1024px
        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
          freeMode: false
        }
      }
    }
    this.offset = {
      slideWidth: this.slideWidth,
      slideHeight: this.slideWidth,
      spaceBetween: this.spaceBetween
    };

  }
  private setSrcImages(elm): void {
    let duplicates = elm.querySelectorAll(".swiper-slide-duplicate");
    duplicates.forEach(item => {
      let imgs = item.querySelectorAll(".lazyload");
      imgs.forEach(item => {
        if (!item.hasAttribute("src") || !item.hasAttribute("srcset")) {
          item.setAttribute("src", item.getAttribute("data-src"));
          item.setAttribute("srcset", item.getAttribute("data-srcset"));
        }
      })
    })
  }

  private swiperConfig(): void {
    setTimeout(() => {
      let staticClasses = ".product-slider";
      let staticContainer = " .swiper-container";
      let classes = staticClasses + "." + this.features.name + staticContainer;
      console.log(classes)
      let elm = this.elementRef.nativeElement.querySelector(classes);

      let swiper = new Swiper(elm, {
        slidesPerView: this.options.pageSize || this.slidesPerView,
        calculateHeight: true,
        updateOnWindowResize: true,
        spaceBetween: this.spaceBetween,
        mousewheel: {
          invert: false,
          forceToAxis: true
        },
        loop: true,
        freeMode: false,
        lazy: {
          loadOnTransitionStart: false,
          loadPrevNext: true,
        }, // Enable lazy loading
        preloadImages: false, // Disable preloading of all images
        watchSlidesVisibility: true,
        scrollbar: false,
        observer: true,
        autoplay: false,
        effect: "slide",
        navigation: {
          nextEl: "." + this.features.name + " .swiper-button-next",
          prevEl: "." + this.features.name + " .swiper-button-prev"
        },
        on: {
          init: () => {
            this.setSrcImages(elm);
          },
          observerUpdate: () => {
            /* do something */
            console.log("observerUpdate")
                let cards = elm.querySelectorAll(".card-img-hover");
                cards.forEach(card => {
                  card.style.removeProperty('height');
                  card.style.removeProperty('width');
                  card.style.removeProperty('margin-right');
                })

                let slidesUpdate = elm.querySelectorAll(".swiper-slide");
                slidesUpdate.forEach(slide => {
                  slide.style.removeProperty('height');
                })
              
            // console.log(this.slideWidth)
          },
        },
        breakpoints: this.breakpoints
      });
      // window.onresize = () => {
      //   console.log('product resize');
      //   this.progressLayout();
      //   setTimeout(()=> {

      //     swiper.update();
      //     swiper.slides.forEach(slide => {
      //       slide.style.width = this.slideWidth + 'px';
      //       slide.style.height = this.slideHeight + 'px';
      //       slide.style.marginRight = this.spaceBetween + 'px';
      //     })
      //     swiper.setTranslate(0);
      //   })
      // }
      // window.onresize = () => {
      //   console.log('resize', this.offset);
      //   this.progressLayout();
      //   setTimeout(()=> {
      //     swiper.update();
      //     let staticClasses = ".product-slider";
      //     let staticContainer = " .swiper-container";
      //     let classes = staticClasses + "." + this.features.name + staticContainer;

      //     let elm = this.elementRef.nativeElement.querySelector(classes);
      //     console.log(elm)
      //     elm.querySelectorAll(".card-img-hover").forEach(item => {
      //       item.style.width = this.offset.slideWidth + 'px';
      //       item.style.height = this.offset.slideHeight + 'px';
      //     })
      //   })
      // }

      // let selfRouter = this.router;
      // this.swiper.on("click", function(e) {
      //   let target = e.target;
      //   console.log(target);
      //   if (target.nodeName == "IMG") {
      //     target = e.target.parentNode.parentNode.parentNode;
      //   }
      //   if (target) {
      //     let url = target.getAttribute("rel");
      //     if (url) {
      //       selfRouter.navigate([url]);
      //       sessionStorage.setItem("redirectUrl", url);
      //     }
      //   }
      // });
    });
  }



}
