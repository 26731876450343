import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Shop, ShopListConfig } from '../models';
import { distinctUntilChanged, map } from 'rxjs/operators';
;

@Injectable({
  providedIn: 'root'
})
export class ShopService {
  private currentShopSubject = new BehaviorSubject<any>({} as any);
  public currentShop = this.currentShopSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private apiService: ApiService) { }
  query(config: ShopListConfig): Observable<{ shops: Shop[], shopsCount: number }> {
    // Convert any filters over to Angular's URLSearchParams
    const params = {};

    Object.keys(config.filters)
      .forEach((key) => {
        params[key] = config.filters[key];
      });

    return this.apiService
      .get('/shops/search', new HttpParams({ fromObject: params }));
  }

  getShop(slug): Observable<any> {
    return this.apiService.get(`/shops/${slug}`).pipe(map(resp => {
      return resp;
    }))
  }

  setShop(shop) {
    // Set current shop data into observable
    this.currentShopSubject.next(shop);
  }

  getCurrentShop() {
    return this.currentShopSubject.value;
  }

  purgeShop() {
    // Set current shop to an empty object
    this.currentShopSubject.next({} as any);
  }

}
