<!-- FOOTER -->
<footer>
  <div class="py-8">
    <div class="container">
      
      <div class="row">
        <div class="col-6 col-sm-2">

          <!-- Heading -->
          <h6 class="heading-xxs mb-4 text-black">
            Noam
          </h6>

          <!-- Links -->
          <ul class="list-unstyled mb-0">
            <li>
              <a href="./about.html">Our Story</a>
            </li>
            <li>
              <a href="./about.html">Delivery</a>
            </li>
            <li>
              <a href="./about.html">Payment</a>
            </li>
            <li>
              <a href="./about.html">Refund & Return</a>
            </li>
            <li>
              <a href="./about.html">FAQs</a>
            </li>
          </ul>

        </div>
        <div class="col-6 col-sm-2">

          <!-- Heading -->
          <h6 class="heading-xxs mb-4 text-black">
            Connect
          </h6>

          <!-- Links -->
          <ul class="list-unstyled mb-0">
            <li>
              <a href="./about.html">Facebook</a>
            </li>
            <li>
              <a href="./about.html">Instagram</a>
            </li>
            <li>
              <a href="./about.html">helo@noam.vn</a>
            </li>
          </ul>

        </div>
        <div class="col-12 col-sm-5 offset-sm-3 hidden-sm d-flex flex-column justify-content-start">
          <p class="mb-8 font-size-sm text-muted">
            Noam is the best Viet-made, curated. Sign up to enjoy free shipping and returns on your first order.
          </p>
          <!-- Form -->
          <form class="navbar-form w-100">
            <div class="input-group">

              <!-- Input -->
              <input class="form-control form-control-underline form-control-sm border-dark" type="search"
                placeholder="Email Address">

              <!-- Button -->
              <div class="input-group-append">
                <button class="btn btn-underline btn-sm border-dark" type="button">
                  <i class="fal fa-long-arrow-right"></i>
                </button>
              </div>

            </div>
          </form>

        </div>

      </div>
      <div class="row">
        <div class="col">
          <!-- Copyright -->
          <p class="pt-2 mb-3 mb-md-0 font-size-xxs text-muted">
            Copyright © 2020 Noam Group Vietnam
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- <footer id="footer">
  <div class="footer-widget">
    <div class="container">
      <div class="row mobile">
        <div class="col-sm-5 visible-sm">
          <div class="single-widget newsletter">
            <h2 class="lowercase">Noam is the best Viet-made, curated. Sign up to enjoy free shipping and returns on
              your first order.</h2>
            <newsletter></newsletter>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="single-widget" [ngClass]="clicked === 0 ? 'active minus' : 'plus'">
            <h2 class="single" translate (click)="select(0)">Noam</h2>
            <ul>
              <li><a href="#" translate>Our Story</a></li>
              <li><a href="#" translate>Shipping</a></li>
              <li><a href="#" translate>Payment</a></li>
              <li><a href="#" translate>Returns & Refund</a></li>
              <li><a href="#" translate>FAQs</a></li>
            </ul>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="single-widget" [ngClass]="clicked === 1 ? 'active minus' : 'plus'">
            <h2 class="single" (click)="select(1)">Connect</h2>
            <ul>
              <li><a href="#" translate>Facebook</a></li>
              <li><a href="#" translate>Instagram</a></li>
              <li><a href="#" translate>helo@noam.vn</a></li>
            </ul>
          </div>
        </div>
        <div class="col-sm-5 col-sm-offset-3 hidden-sm">
          <div class="single-widget">
            <h2 class="lowercase">Noam is the best Viet-made, curated. Sign up to enjoy free shipping and returns on
              your first order.</h2>
            <newsletter></newsletter>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          Copyright © 2020 Noam Group Vietnam
        </div>
      </div>
    </div>
  </div>
</footer> -->
