import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()

export class ImageService {

    public mediaUrl: any;

    constructor() { }

    public CFImage(fileUrl, options, cloudOpts) {
        if (!options) options = {};
        if (!fileUrl) {
            this.mediaUrl = '';
        } else if (this.isUrl(fileUrl)) {
            this.mediaUrl = fileUrl;
        } else {
            if (!options.keepFormat) {
                cloudOpts.edits.jpeg = {};
            }
            if (fileUrl) {
                cloudOpts.key = fileUrl;
            }
            if (options) {
                if (options.height > 0) {
                    cloudOpts.edits.resize.height = options.height;
                }
                if (options.width > 0) {
                    cloudOpts.edits.resize.width = options.width;
                }
            }
            this.mediaUrl = environment.cloudfontDynamicUrl + window.btoa(JSON.stringify(cloudOpts));
        }
        return this.mediaUrl;
    }
    public getCFImage(fileUrl, options) {
        var cloudOpts: any = {
            bucket: environment.bucket,
            edits: {
                resize: {
                    fit: options && options.fit ? options.fit : 'cover',
                    withoutEnlargement: false
                },
                // normalise: true
            }

        };

        return this.CFImage(fileUrl, options, cloudOpts)
    }

    public getCFImageForOG(fileUrl) {

        let options: any = {
        };

        return this.getCFImage(fileUrl, options)

    }

    // For Open Graph it’s 1200 x 630. For twitter cards using summary_large_image it’s an aspect ratio of 2:1 between 300x157 and 4096x4096 pixels. Anything outside the aspect ratio though is cropped out. Images must be less than 5MB in size. JPG, PNG, WEBP and GIF formats are supported. Only the first frame of an animated GIF will be used. SVG is not supported.
    public getCFImageForTW(fileUrl) {
        let options: any = {
            height: 300,
            width: 600
        };
        var cloudOpts: any = {
            bucket: environment.bucket,
            edits: {
                resize: {
                    fit: "contain",
                    "background": {
                        "r": 255,
                        "g": 255,
                        "b": 255,
                        "alpha": 0
                    }
                }
            }
        };
        return this.CFImage(fileUrl, options, cloudOpts)
    }


    isUrl(str) {
        const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        return regex.test(str);
    };
}