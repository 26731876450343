<!-- NAVBAR -->
<nav class="navbar navbar-expand-md navbar-light">
  <div class="container-fluid">
    <!-- Brand -->
    <!-- <a class="navbar-brand logo d-md-none" href="./overview.html">
      <img src="{{ '/assets/images/logo.png' }}">
    </a> -->

    <!-- Collapse -->
    <div class="justify-content-between justify-content-center d-flex w-100" >
      <app-left-header></app-left-header>
      <app-center-header></app-center-header>
      <app-right-header></app-right-header>
    </div>

  </div>
</nav>