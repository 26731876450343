<div class="product-slider slider-customize {{features.name}}">
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let product of products, let i= index" >
        <app-product-card [product]="product" [offset]="offset" ></app-product-card>
      </div>
    </div>
  </div>
  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div>
</div>
