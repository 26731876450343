<div class="shop-slider slider-customize"  >
  <div class="swiper-container">
    <div class="swiper-wrapper" *ngIf="shops.length">
      <div class="swiper-slide"  *ngFor="let shop of shops, let i= index" [ngStyle]="{'width': slideWidth +'px', 'height': slideHeight +'px', 'margin-right': spaceBetween + 'px'}">
        <a class="w-100 h-100 d-block picture position-relative  rounded-circle" [rel]="shop.alias">
          <img [attr.data-src]="shop.originalCover" [attr.data-srcset]="shop.cover"
          class="rounded-circle s-cover w-100 swiper-lazy" >
          <img [attr.data-src]="shop.originalLogo" [attr.data-srcset]="shop.logo" class="s-logo swiper-lazy"/>
          <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
        </a>
      </div>
    </div>
  </div>
  <div class="swiper-button-next" *ngIf="shops && shops.length > slidesPerView"></div>
  <div class="swiper-button-prev" *ngIf="shops && shops.length > slidesPerView"></div>
</div>
<!-- [ngStyle]="{'height': swiperSlide.offsetWidth +'px'}"
#swiperSlide (window.resize)="0" -->