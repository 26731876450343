import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '..';
import { LeftHeaderComponent } from './left-header/left-header.component';
import { RightHeaderComponent } from './right-header/right-header.component';
import { RouterModule } from '@angular/router';
import { CenterHeaderComponent } from './center-header/center-header.component';

@NgModule({
  declarations: [
    LeftHeaderComponent,
    RightHeaderComponent,
    HeaderComponent,
    CenterHeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
