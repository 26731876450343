import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService, AuthService, BannerService, ImageService, JwtService, ProductService, ShopService, TranslateService} from './services';
import { HttpTokenInterceptor } from './interceptors';
import { UrlSerializer } from '@angular/router';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    ApiService,
    AuthService,
    TranslateService,
    BannerService,
    JwtService,
    ImageService,
    ShopService,
    ProductService
  ]
})
export class CoreModule { }
