import { AuthService } from 'src/app/core/services';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-right-header',
  templateUrl: './right-header.component.html',
  styleUrls: ['./right-header.component.scss']
})
export class RightHeaderComponent implements OnInit {

  constructor(
    private authService: AuthService
  ) {}

  currentUser: any;

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
        console.log(this.currentUser)
      }
    );
  }

}
