import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Banner, BannerListConfig } from '../models';


@Injectable({
  providedIn: 'root'
})
export class BannerService {
  constructor(
    private apiService: ApiService
  ) { }

  query(config: BannerListConfig): Observable<{ banners: Banner[], bannersCount: number }> {
    // Convert any filters over to Angular's URLSearchParams
    const params = {};

    // Object.keys(config.filters)
    //   .forEach((key) => {
    //     params[key] = config.filters[key];
    //   });

    return this.apiService
      .get('/banners/random', new HttpParams({ fromObject: params }));
  }

  get(slug): Observable<Banner> {
    return this.apiService.get('/banners/' + slug)
      .pipe(map(data => data.banner));
  }

  destroy(slug) {
    return this.apiService.delete('/banners/' + slug);
  }

  save(banner): Observable<Banner> {
    // If we're updating an existing banner
    if (banner.slug) {
      return this.apiService.put('/banners/' + banner.slug, { banner: banner })
        .pipe(map(data => data.banner));

      // Otherwise, create a new banner
    } else {
      return this.apiService.post('/banners/', { banner: banner })
        .pipe(map(data => data.banner));
    }
  }

  favorite(slug): Observable<Banner> {
    return this.apiService.post('/banners/' + slug + '/favorite');
  }

  unfavorite(slug): Observable<Banner> {
    return this.apiService.delete('/banners/' + slug + '/favorite');
  }


}
