<!-- Nav -->
<ul class="navbar-nav">
  <li class="nav-item dropdown position-static dropdown-activated">

    <!-- Toggle -->
    <a class="nav-link nav-link-cus" data-toggle="dropdown" href="#">Brands</a>

    <!-- Menu -->
    <div class="dropdown-menu w-100">
      <div class="header-brands dropdown-custom pull-left">
        <div class="dropdown-content">
          <ul>
            <li *ngFor="let dp of departments">
              <a (click)="goToDepartment(dp)">{{dp.title}}</a>
              <ul>
                <li *ngFor="let b of dp.brands"><a (click)="goLinkByUrl(b.link)">{{b.title}}</a></li>
              </ul>
            </li>
          </ul>
          <div class="see-all">
            <a (click)="goLinkByUrl('n/brands/search')">See All Brands <i class="fal fa-long-arrow-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </li>
  <li class="nav-item collapse navbar-collapse">
    <a class="nav-link" href="./docs/getting-started.html">About</a>
  </li>
  <li class="nav-item" [hidden]="isNoam">
    <a class="nav-link" [routerLink]="['/']">
      <i class="fad fa-home-lg-alt"></i>
    </a>
  </li>
</ul>
