import { ElementRef } from '@angular/core';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
declare var LazyLoad: any;
// const lazyload = (window as any).lazyload;


@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductCardComponent implements OnInit {
  @Input() product: any = {};
  @Input() offset: any = {};
  @Input() shopId: string = null;
  isLoaded: boolean = false;
  constructor(private elementRef: ElementRef,) { }

  ngOnInit(): void {
    // this.product.image = "https://hd.unsplash.com/photo-1431400445088-1750c997c6b5";
  }
  ngAfterViewInit(): void {
    console.log('init');
    let images = document.querySelectorAll(".lazyload");
    new LazyLoad(images);
    // lazyload();
  }
  onLoad() {

  }
}

