<!-- Nav -->
<ul class="navbar-nav flex-row">
  <li class="nav-item ml-lg-n4 collapse navbar-collapse" *ngIf="!currentUser || !currentUser.name">
    <a class="nav-link" [routerLink]="['n/auth/login']">
      <i class="fal fa-user"></i> Account
    </a>
  </li>

  <li class="nav-item ml-lg-n4 collapse navbar-collapse" *ngIf="currentUser && currentUser.name">
    <a class="nav-link" [routerLink]="['n/auth/login']">
      <i class="fal fa-user"></i> {{ currentUser.name }}
    </a>
  </li>

  <li class="nav-item ml-lg-n4">
    <a class="nav-link" data-toggle="modal" href="#modalShoppingCart">
      <span data-cart-items="2">
        <i class="fal fa-shopping-bag"></i>
      </span>
    </a>
  </li>
</ul>

