<div class="card">
  <!-- Badge -->
  <!-- <div class="badge badge-white card-badge card-badge-left text-uppercase">
    New
  </div> -->

  <!-- Image -->
  <div class="card-img">
    <!-- Image -->
    <a class="card-img-hover thumb" [routerLink]="['/', product.shop.alias, product.alias]" [ngStyle]="{'width': offset.slideWidth +'px', 'height': offset.slideHeight +'px','margin-right': offset.spaceBetween + 'px'}">
        <img class="card-img-top card-img-back rounded-0 lazyload" [attr.data-srcset]="product.image" [attr.data-src]="product.originalImage" onerror="this.parentNode.classList.add('card-error')" onload="this.parentNode.classList.add('card-loaded')"/>
        <img class="card-img-top card-img-front rounded-0 lazyload" [attr.data-srcset]="product.image" [attr.data-src]="product.originalImage" onerror="this.parentNode.classList.add('card-error')" onload="this.parentNode.classList.add('card-loaded')"/>
      <i class="fal fa-exclamation-triangle" style="display: none;"></i>
    </a>

    <!-- Actions -->
    <div class="card-actions">
      <span class="card-action">
        <button class="btn btn-xs btn-circle btn-white-primary" data-toggle="modal" data-target="#modalProduct">
          <i class="fal fa-eye"></i>
        </button>
      </span>
      <span class="card-action">
        <button class="btn btn-xs btn-circle btn-white-primary" data-toggle="button">
          <i class="fal fa-shopping-cart"></i>
        </button>
      </span>
      <span class="card-action">
        <button class="btn btn-xs btn-circle btn-white-primary" data-toggle="button">
          <i class="fal fa-heart"></i>
        </button>
      </span>
    </div>

  </div>

  <!-- Body -->
  <div class="card-body p-0 pt-3">

    <div class="row no-gutters">
      <div class="col">

        <!-- Title -->
        <a class="d-block font-weight-bold text-body" href="product.html">
          {{product.name}}
        </a>

        <!-- Category -->
        <a class="font-size-xs text-muted text-capitalize" href="shop.html">
          {{product?.shop?.name | lowercase}}
        </a>

      </div>
      <div class="col-auto">

        <!-- Price -->
        <div class="font-size-sm font-weight-bold text-muted">
          {{product.price}} đ
        </div>

      </div>
    </div>

  </div>

</div>
