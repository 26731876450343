import {
  Component,
  Input,
  OnInit,
  ElementRef,
} from "@angular/core";
import { ImageService } from 'src/app/core';
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "lazy-image",
  templateUrl: "./lazy-image.html"
})
export class LazyImageComponent implements OnInit {
  @Input() public fileUrl;
  @Input() public classImg;
  @Input() public options;
  public isShow: boolean = false;
  public fullImage: any;

  constructor(
    private imageService: ImageService,
    private elementRef: ElementRef,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.fullImage = this.imageService.getCFImage(this.fileUrl, this.options);
    this.loadImage();
  }
  ngAfterViewInit() {
    if (this.deviceService.isDesktop()) {
      let elm = this.elementRef.nativeElement.querySelector(".media-zoom");
      if (elm) {
        elm.addEventListener("dblclick", e => {
          this.elementRef.nativeElement
            .querySelector(".pz-zoom-button")
            .click();
        });
      }
    }
  }

  loadImage() {
    var pItem = document.getElementsByClassName("lazy replace")[0];
    if (pItem) {
      this.loadFullImage(pItem, 0);
      pItem.classList.remove("replace");
      pItem.classList.add("loading");
    } else {
      // console.log("not have lazy item", this.fileName);
    }
  }
  // replace with full image
  loadFullImage(item, retry) {
    // let file = this.fileName;
    var href = this.fullImage;
    // console.log("loadFullImage for", this.fileName);
    if (!href) return;

    // load image
    var img = new Image(),
      ds = item.dataset;
    if (ds) {
      if (ds.srcset) img.srcset = ds.srcset;
      if (ds.sizes) img.sizes = ds.sizes;
    }
    img.onload = addImg;
    retry = 1 + (retry || 0);
    var _this = this;
    if (retry < 3)
      img.onerror = function(e) {
        // console.log("onerror", this.fileName, e);
        setTimeout(function() {
          // console.log("reload image", this.fileName);
          _this.loadFullImage(item, retry);
        }, retry * 3000);
      };
    var classes = ["image-responsive"];
    if (this.classImg) {
      classes.push(this.classImg);
    }
    classes.push("reveal");
    img.className = classes.join(" ");
    // console.log("img.className", img.className);
    img.src = href;

    // replace image
    function addImg() {
      // console.log("addImg-0", file);
      // setTimeout(function() {
      // requestAnimationFrame(function() {
      // preview image
      // var pImg = item.querySelector && item.querySelector(".image");
      // console.log("addImg-1", file);
      // check to play animation or not
      var wH = window.innerHeight,
        cRect,
        cT,
        cH;
      cRect = item.getBoundingClientRect();
      cT = cRect.top;
      cH = cRect.height;

      if (0 < cT + cH && wH > cT) {
        // console.log("ON screen, play animation", file);
        // on screen, play animation
        // add full image
        item.appendChild(img).addEventListener("animationend", function(e) {
          // console.log("appendChild", file);
          if (e.target.src != href) {
            // console.log(" finish animation diff href\n", file);
          }
          if (e && e.animationName == "progressiveReveal") {
            // console.log("finish load image-0", file);
            // remove preview image
            // if (pImg) {
            //   if (pImg.alt) img.alt = pImg.alt;
            //   // item.removeChild(pImg);
            // }
            item.className = "swiper-zoom-container lazy";
            img.classList.remove("reveal");
            _this.isShow = true;
          }
        });
      } else {
        // console.log("OFF screen, no animation", file);
        // off-screen
        item.appendChild(img);
        // console.log("finish load image-1", file);
        // remove preview image
        // if (pImg) {
        //   if (pImg.alt) img.alt = pImg.alt;
        //   // item.removeChild(pImg);
        // }
        item.className = "swiper-zoom-container lazy";
        img.classList.remove("reveal");
        _this.isShow = true;
      }

      // });
      // }, retry * 2000);
    }
  }
}
