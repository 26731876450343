// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleAnalyticsKey: "UA-150358185-2",
  version: "0.0.1",
  build: 27,
  apiBaseUrl: "http://localhost:9000/v1",
  apiProxy: "http://localhost:9000/v1",
  sellerUrl: "https://seller.noam.vn",
  platform: "web",
  googleClientId:
    "917195124089-hp1m6g5fbotlkbipmrejrapt9uh0lhhl.apps.googleusercontent.com",
  facebookAppId: "463161567871350",
  paymentRedirectSuccessUrl: "http://localhost:4200/cart/checkout/success",
  paymentRedirectCancelUrl: "http://localhost:4200/cart/checkout/cancel",
  paymentRedirectFailUrl: "http://localhost:4200/n/cart/reviews",
  paydollarPath: "https://test.paydollar.com/b2cDemo/eng/payment/payForm.jsp",
  stripeKey: "pk_test_aHRUmyxElC3clmaKJn8SEBXH",
  pusher: {
    appId: 591974,
    key: "8cbf727dad3c8ce84888",
    cluster: "ap1"
  },
  showBuild: true,
  cloudFrontUrl: "http://localhost:4200", // noam static files with gzip enable
  cloudfontDynamicUrl: "https://de6a6i59a6ooh.cloudfront.net/",
  defaultLang: "en",
  bucket: "noam-media"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
